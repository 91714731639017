import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import isEmpty from 'lodash/fp/isEmpty';

import { LogoHolderProps, LogoItemProps, LogoVariant, LogoVariants } from './types';

export const getWaveMainPageUrl = (waveUrl: string) => (hasAuthAccess() ? `${waveUrl}main` : waveUrl);

export const getLogos = (variant: LogoVariant, studioUrl?: string, waveMainPageUrl?: string): LogoItemProps => {
  const logos: { [key: string]: LogoItemProps } = {
    ...(studioUrl && getLogosStudioUrl(studioUrl)),
    ...(waveMainPageUrl && getLogosWithWaveUrl(waveMainPageUrl)),
    ...getLogosWithoutUrl()
  };

  const logo: LogoItemProps = logos[variant];

  if (isEmpty(logo)) {
    throw Error(`does not find logo for variant: ${variant}`);
  }

  return logo;
};

export const getLogosWithoutUrl = (): Pick<LogoVariants, 'app-sumo' | 'wave-no-link'> => ({
  'app-sumo': {
    href: 'some-app-sumo-utl', // TODO add real url
    iconSrc: 'images/logos/app-sumo-logo.svg',
    alt: 'AppSumo'
  },
  'wave-no-link': {
    iconSrc: 'images/logos/wv-logo-140-1.svg',
    alt: 'Wave.video'
  }
});

export const getLogosWithWaveUrl = (
  waveMainPageUrl: string
): Pick<LogoVariants, 'wave' | 'dark-wave' | 'small-footer' | 'wave-hosting' | 'live' | 'lives'> => ({
  wave: {
    href: waveMainPageUrl,
    iconSrc: 'images/logos/wv-logo-140-1.svg',
    alt: 'Wave.video'
  },
  'dark-wave': {
    href: waveMainPageUrl,
    iconSrc: 'images/logos/wv-logo-140.svg',
    alt: 'Wave.video'
  },

  'small-footer': {
    href: waveMainPageUrl,
    iconSrc: 'images/logos/wv-logo-140.svg',
    alt: 'Wave.video'
  },

  'wave-hosting': {
    href: waveMainPageUrl,
    iconSrc: 'images/logos/wave-logo-hosting.svg',
    alt: 'Wave.video',
    textLogo: 'Hosting'
  },

  live: {
    href: waveMainPageUrl,
    iconSrc: 'images/logos/live.svg',
    alt: 'Wave.video live',
    textLogo: 'Live'
  },
  lives: {
    href: '/lives',
    iconSrc: 'images/logos/live.svg',
    alt: 'Wave.video live',
    textLogo: 'Live'
  }
});

export const getLogosStudioUrl = (studioUrl: string): Pick<LogoVariants, 'studio' | 'studio_logo_black'> => ({
  studio: {
    href: `${studioUrl}`,
    iconSrc: 'images/logos/animatron-logo.svg',
    alt: 'Animatron'
  },

  studio_logo_black: {
    href: `${studioUrl}`,
    iconSrc: 'images/logos/studio_logo_black.svg',
    alt: 'Animatron'
  }
});

export const isLink = ({ href }: LogoHolderProps) => !!href;
